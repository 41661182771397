import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
import PortableText from "../components/Blog/portableText";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import { toPlainText } from "../lib/helpers";
// import ValueProps from "../components/Repeating/ValueProps";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonWithIcon from "../components/Button/ButtonWithIcon";
import ModalTeamMembers from "../components/Modal/ModalTeamMembers";
import Badges from "../components/Repeating/Badges";
import CallToAction from "../components/Repeating/CTA";
import RecentPosts from "../components/Repeating/RecentPosts";
import Subscribe from "../components/Repeating/Subscribe";
import Testimonials from "../components/Repeating/Testimonials";
import Slider from "../components/Slider/SliderTeamMemberHero";

export const query = graphql`
  query LocationTemplateQuery($id: String!) {
    page: sanityLocation(id: { eq: $id }) {
      id
      seoTitle
      metaDescription
      openGraphImage {
        asset {
          url
        }
      }
      twitterCardImage {
        asset {
          url
        }
      }
      title
      phoneNumber
      heroHeading
      cityImage {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 1376)
        }
      }
      cityHeading
      _rawCityText(resolveReferences: { maxDepth: 5 })
      introQuote {
        quote
        teamMember {
          name
          headshot {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 64)
            }
          }
          title
          hubspotID
        }
      }
      businessSolutionsQuote {
        quote
        teamMember {
          name
          headshot {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 64)
            }
          }
          title
          hubspotID
        }
      }
      personalTaxesQuote {
        quote
        teamMember {
          name
          headshot {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 64)
            }
          }
          title
          hubspotID
        }
      }
      testimonials {
        title
      }
      teamMembers {
        name
        headshot {
          asset {
            gatsbyImageData(layout: CONSTRAINED, width: 330)
          }
        }
        directPhoneNumber
        emailAddress
        linkedinProfile
        hubspotID
        hubspotOwnerID
        title
        hometown
        yearsExperience
        education {
          asset {
            gatsbyImageData(layout: CONSTRAINED, width: 80)
          }
        }
        quote
        quoteAuthor
        _rawBiography
        certifications {
          listItem
        }
        specialties {
          listItem
        }
        industries {
          listItem
        }
      }
    }
    grayCrosses: file(relativePath: { eq: "locations/gray-crosses.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    business: file(relativePath: { eq: "locations/business.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 528)
      }
    }
    personal: file(relativePath: { eq: "locations/personal.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 528)
      }
    }
    orangeSquare: file(
      relativePath: { eq: "global/decorative-orange-square.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    orangeCrosses: file(
      relativePath: { eq: "global/decorative-orange-crosses.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 192)
      }
    }
    blueSquare: file(
      relativePath: { eq: "global/decorative-blue-square.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    blueCrosses: file(
      relativePath: { eq: "global/decorative-blue-crosses.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 192)
      }
    }
  }
`;

const LocationsTemplate = (props) => {
  const { data, errors } = props;
  const page = data && data.page;

  const truncate = (str) => {
    return str.length > 300 ? str.substring(0, 300) : str;
  };

  // Add the first CPA's email address to local storage.
  // Used for Hubspot integration
  let contactOwner;
  page.teamMembers.length > 0
    ? page.teamMembers
        .slice(0, 1)
        .map((teamMember) => (contactOwner = teamMember.hubspotOwnerID))
    : (contactOwner = 41454685);

  const setLocalStorage = () => {
    localStorage.setItem("contact-owner", contactOwner);
  };

  const [slideIndex, setSlideIndex] = useState(0);

  const CityHeading = page.teamMembers.length > 0 ? "h2" : "h1";

  return (
    <Layout phoneNumber={page.phoneNumber} contactOwner={contactOwner}>
      {errors && <SearchEngineOptimization title="GraphQL Error" />}
      {page && (
        <SearchEngineOptimization
          title={page.seoTitle}
          description={page.metaDescription}
          openGraphImage={page.openGraphImage && page.openGraphImage.asset.url}
          twitterOpenGraphImage={
            page.twitterCardImage && page.twitterCardImage.asset.url
          }
        />
      )}

      {errors && (
        <div className="container">
          <GraphQLErrorList errors={errors} />
        </div>
      )}

      {page && (
        <>
          {page.teamMembers.length > 0 && (
            <section className="relative mb-68 pt-8 md:mb-72 md:pt-12">
              <div className="absolute top-0 left-0 h-full w-full bg-primary-900"></div>
              <div className="absolute top-0 left-0 h-full w-full ">
                <GatsbyImage
                  image={data.grayCrosses.childImageSharp.gatsbyImageData}
                  className="h-full"
                />
              </div>
              <div className="container relative">
                <header className="mx-auto mb-4 max-w-4xl text-center md:mb-8">
                  <h1 className="mb-0 text-white">{page.heroHeading}</h1>
                </header>
                <div className="relative -bottom-44 -mt-40 rounded-xl bg-secondary-100 px-4 py-6 md:px-16 md:py-16">
                  <div className="relative">
                    <Slider>
                      {page.teamMembers.map((teamMember, i) => (
                        <div key={i}>
                          <div className="grid grid-cols-1 md:grid-cols-12 md:gap-x-10">
                            <div className="flex flex-col justify-between md:col-span-4 md:col-start-1">
                              <div>
                                <div className="relative mx-auto mb-6 h-22 w-22 overflow-hidden rounded-full border-2 border-primary-500 md:mx-0 md:mb-8 md:h-auto md:w-auto md:overflow-visible md:rounded-none md:border-none md:pl-1.5 md:pt-1.5">
                                  <div className="absolute left-0 top-0 hidden h-full w-full rounded-4xl bg-primary-500 md:block"></div>
                                  <GatsbyImage
                                    image={
                                      teamMember.headshot.asset.gatsbyImageData
                                    }
                                    className="z-0 rounded-full md:rounded-4xl"
                                  />
                                </div>

                                {teamMember.certifications && (
                                  <div className="mb-8 hidden md:block">
                                    <div className="mb-3.5 text-xs font-bold uppercase tracking-wide text-primary-900/40">
                                      Certifications
                                    </div>
                                    <ul className="styled-list-star">
                                      {teamMember.certifications.map(
                                        (certification, i) => {
                                          return (
                                            <li key={i}>
                                              {certification.listItem}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                )}
                              </div>

                              {teamMember.hubspotID && (
                                <div className="hidden md:block">
                                  <ButtonSolid
                                    href={`/book-a-meeting/?hubspotID=${teamMember.hubspotID}`}
                                    text="Book a Meeting"
                                    className="w-full"
                                  />
                                </div>
                              )}
                            </div>
                            <div className="md:col-span-8 md:col-end-13">
                              <h2 className="text-center md:mb-8 md:text-left">
                                {teamMember.name}
                              </h2>

                              <div className="mb-5 flex flex-wrap space-y-4 lg:space-y-0 lg:space-x-6">
                                {teamMember.directPhoneNumber && (
                                  <a
                                    href={`tel:${teamMember.directPhoneNumber
                                      .replace(/[()]/g, "")
                                      .replace(/ /g, "-")}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex w-full items-center justify-center text-primary-900 no-underline hover:text-secondary-500 md:justify-start lg:w-auto"
                                  >
                                    <i className="fa-solid fa-phone mr-1.5 text-xl text-secondary-500"></i>
                                    <span className="font-semibold">
                                      {teamMember.directPhoneNumber}
                                    </span>
                                  </a>
                                )}

                                {teamMember.emailAddress && (
                                  <a
                                    href={`mailto:${teamMember.emailAddress}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex w-full items-center justify-center text-primary-900 no-underline hover:text-secondary-500 md:justify-start lg:w-auto"
                                  >
                                    <i className="fa-solid fa-envelope mr-1.5 text-xl text-secondary-500"></i>
                                    <span className="font-semibold">
                                      {teamMember.emailAddress}
                                    </span>
                                  </a>
                                )}

                                {teamMember.linkedinProfile && (
                                  <a
                                    href={teamMember.linkedinProfile}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="flex w-full items-center justify-center text-primary-900 no-underline hover:text-secondary-500 md:justify-start lg:w-auto"
                                  >
                                    <i className="fab fa-linkedin mr-1.5 text-2xl text-secondary-500"></i>
                                    <span className="font-semibold">
                                      LinkedIn
                                    </span>
                                  </a>
                                )}
                              </div>

                              <div className="mb-6 grid grid-cols-2 rounded-3xl border border-gray-900/40 bg-white text-center md:mb-10 md:flex md:rounded-lg md:text-left">
                                {teamMember.title && (
                                  <div
                                    className={`border-b border-primary-900/40 py-5 px-4 md:flex-1 md:border-b-0 md:py-4`}
                                  >
                                    <div className="mb-0.5 text-xs font-bold uppercase tracking-wide text-primary-900/40 md:mb-2.5">
                                      Title
                                    </div>
                                    <div className="text-sm font-semibold text-primary-900 md:text-lg md:font-bold">
                                      {teamMember.title}
                                    </div>
                                  </div>
                                )}

                                {teamMember.hometown && (
                                  <div
                                    className={`border-l border-b border-primary-900/40 py-5 px-4 md:flex-1 md:border-b-0 md:py-4`}
                                  >
                                    <div className="mb-0.5 text-xs font-bold uppercase tracking-wide text-primary-900/40 md:mb-2.5">
                                      Hometown
                                    </div>
                                    <div className="text-sm font-semibold text-primary-900 md:text-lg md:font-bold">
                                      {teamMember.hometown}
                                    </div>
                                  </div>
                                )}

                                {teamMember.yearsExperience && (
                                  <div
                                    className={`border-primary-900/40 py-5 px-4 md:flex-1 md:py-4 ${
                                      teamMember.hometown === null
                                        ? "border-l border-b md:border-b-0"
                                        : "md:border-l"
                                    }`}
                                  >
                                    <div className="mb-0.5 text-xs font-bold uppercase tracking-wide text-primary-900/40 md:mb-2.5">
                                      Years of Experience
                                    </div>
                                    <div className="text-sm font-semibold text-primary-900 md:text-lg md:font-bold">
                                      {teamMember.yearsExperience}
                                    </div>
                                  </div>
                                )}

                                {teamMember.education.length > 0 && (
                                  <div
                                    className={`border-primary-900/40 py-5 px-4 md:flex-1 md:py-4 ${
                                      teamMember.hometown === null &&
                                      teamMember.yearsExperience === null
                                        ? "border-l"
                                        : teamMember.hometown === null ||
                                          teamMember.yearsExperience === null
                                        ? "col-span-2 md:border-l"
                                        : "border-l"
                                    }`}
                                  >
                                    <div className="mb-1 text-xs font-bold uppercase tracking-wide text-primary-900/40 md:mb-2.5">
                                      Education
                                    </div>
                                    <div
                                      className={`grid items-center justify-center gap-x-3 md:justify-start md:gap-x-3 ${
                                        teamMember.education.length > 1 &&
                                        "grid-cols-2"
                                      }`}
                                    >
                                      {teamMember.education.map(
                                        (education, i) => {
                                          return (
                                            <GatsbyImage
                                              image={
                                                education.asset.gatsbyImageData
                                              }
                                              className="max-w-[80px]"
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>

                              {teamMember.hubspotID && (
                                <div className="mb-6 md:hidden">
                                  <ButtonSolid
                                    href={`/book-a-meeting/?hubspotID=${teamMember.hubspotID}`}
                                    text="Book a Meeting"
                                    className="w-full"
                                  />
                                </div>
                              )}

                              {teamMember.quote && (
                                <figure>
                                  <blockquote>
                                    <q className="mb-4 block font-bold italic">
                                      {teamMember.quote}
                                    </q>
                                  </blockquote>
                                  {teamMember.quoteAuthor && (
                                    <figcaption className="mb-4 -mt-2 block text-sm font-bold">
                                      &mdash; {teamMember.quoteAuthor}
                                    </figcaption>
                                  )}
                                </figure>
                              )}

                              {teamMember._rawBiography && (
                                <>
                                  <div className="relative mb-1 h-20 overflow-hidden">
                                    <p className="mb-0">
                                      {truncate(
                                        toPlainText(teamMember._rawBiography)
                                      )}
                                    </p>
                                    <div className="absolute left-0 bottom-0 h-full w-full bg-gradient-to-t from-secondary-100 to-white/10"></div>
                                  </div>
                                  <ButtonWithIcon
                                    modal="modal-team-members"
                                    onClick={() => setSlideIndex(i)}
                                    text="Read Full Bio"
                                  />
                                </>
                              )}

                              {(teamMember.specialties ||
                                teamMember.industries) && (
                                <div className="mt-10 hidden gap-x-4 gap-y-8 md:grid md:grid-cols-2">
                                  {teamMember.specialties && (
                                    <div>
                                      <div className="mb-3.5 text-xs font-bold uppercase tracking-wide text-primary-900/40">
                                        Specialties
                                      </div>
                                      <ul className="styled-list-plus">
                                        {teamMember.specialties.map(
                                          (specialty, i) => {
                                            return (
                                              <li key={i}>
                                                {specialty.listItem}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  )}

                                  {teamMember.industries && (
                                    <div>
                                      <div className="mb-3.5 text-xs font-bold uppercase tracking-wide text-primary-900/40">
                                        Industries
                                      </div>
                                      <ul className="styled-list-plus">
                                        {teamMember.industries.map(
                                          (industry, i) => {
                                            return (
                                              <li key={i}>
                                                {industry.listItem}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </section>
          )}

          <section
            className={`mb-20 md:mb-32  ${
              page.teamMembers.length < 1 && "pt-8 md:pt-12"
            }`}
          >
            <div className="container">
              <div
                className={`grid items-center gap-x-10 md:grid-cols-2 lg:gap-x-20 ${
                  page.teamMembers.length > 0 ? "gap-y-10" : "gap-y-8"
                }`}
              >
                <div
                  className={`${
                    page.teamMembers.length > 0 && "order-2 md:order-1"
                  }`}
                >
                  {page.cityImage && (
                    <div className="relative ml-1.5 md:ml-3">
                      <GatsbyImage
                        image={page.cityImage.asset.gatsbyImageData}
                        className="z-0 w-full rounded-4xl"
                      />
                      <div className="absolute -left-1.5 -top-1.5 z-[-10] h-full w-full rounded-3xl bg-primary-500 md:-left-3 md:-top-3"></div>
                    </div>
                  )}
                </div>
                <div
                  className={`${
                    page.teamMembers.length > 0 && "order-1 md:order-2"
                  }`}
                >
                  <CityHeading>{page.cityHeading}</CityHeading>

                  {page._rawCityText && (
                    <PortableText blocks={page._rawCityText} />
                  )}

                  <ButtonSolid
                    href="/get-a-quote/"
                    text="Get a Quote"
                    onClick={setLocalStorage}
                  />
                </div>
              </div>
            </div>
          </section>

          {page.introQuote && (
            <section className="mb-20 md:mb-32">
              <div className="container">
                <div className="mx-auto max-w-[800px] text-center">
                  <div className="mx-auto mb-4 h-16 w-16 overflow-hidden rounded-full border-2 border-secondary-500">
                    <GatsbyImage
                      image={
                        page.introQuote.teamMember.headshot.asset
                          .gatsbyImageData
                      }
                      className="z-0 rounded-full md:rounded-4xl"
                    />
                  </div>
                  <div className="mb-5 text-sm font-medium uppercase tracking-wide text-gray-700/50">
                    {page.introQuote.teamMember.name},{" "}
                    {page.introQuote.teamMember.title}
                  </div>
                  <blockquote className="mb-8">
                    <q className="before:hidden">{page.introQuote.quote}</q>
                  </blockquote>
                  {page.introQuote.teamMember.hubspotID && (
                    <ButtonSolid
                      href={`/book-a-meeting/?hubspotID=${page.introQuote.teamMember.hubspotID}`}
                      text="Book a Meeting"
                    />
                  )}
                </div>
              </div>
            </section>
          )}

          {/* <ValueProps /> */}

          <section className="bg-secondary-100 pt-20 pb-20 md:pt-32 md:pb-36">
            <div className="container">
              <div className="mb-6 grid items-center gap-y-14 md:grid-cols-2 md:gap-x-10 lg:gap-x-28">
                <div>
                  <header>
                    <h2>
                      Accounting Solutions for{" "}
                      <span className="text-secondary-500">Businesses.</span>
                    </h2>
                    <p>
                      The Problem: Business owners aren't getting timely,
                      accurate and actionable data from their financials. Even
                      worse, they're not receiving tax planning advice from
                      their, "tax pro," which is costing them untold amounts of
                      money. This is just plain wrong. We believe you deserve a
                      better small business accounting firm.
                    </p>
                    <ButtonWithIcon
                      href="/small-business-accounting-solutions/"
                      text="Solutions for Businesses"
                    />
                  </header>
                </div>
                <div>
                  <div className="relative ml-8 mr-4 lg:mx-0">
                    <div className="absolute -top-4 -right-4 lg:-top-8 lg:-right-8">
                      <GatsbyImage
                        image={
                          data.orangeSquare.childImageSharp.gatsbyImageData
                        }
                        className="w-[86px] lg:w-[160px]"
                      />
                    </div>
                    <div className="relative z-10">
                      {page.businessSolutionsQuote ? (
                        <div className="flex items-center rounded-4xl bg-white px-6 py-12 shadow-4xl lg:h-[528px] lg:px-12">
                          <div>
                            <div className="mb-2.5 h-16 w-16 overflow-hidden rounded-full border-2 border-secondary-500 lg:mb-4">
                              <GatsbyImage
                                image={
                                  page.businessSolutionsQuote.teamMember
                                    .headshot.asset.gatsbyImageData
                                }
                                className="z-0 rounded-full md:rounded-4xl"
                              />
                            </div>
                            <div className="mb-3 text-xs font-medium uppercase tracking-wide text-gray-700/50 lg:mb-5 lg:text-sm">
                              {page.businessSolutionsQuote.teamMember.name},{" "}
                              {page.businessSolutionsQuote.teamMember.title}
                            </div>
                            <blockquote>
                              <q className="text-sm lg:text-base">
                                {page.businessSolutionsQuote.quote}
                              </q>
                            </blockquote>
                          </div>
                        </div>
                      ) : (
                        <GatsbyImage
                          image={data.business.childImageSharp.gatsbyImageData}
                          className="z-0 w-full rounded-4xl"
                        />
                      )}
                      <div className="absolute -left-1.5 top-1.5 z-[-10] h-full w-full rounded-3xl bg-secondary-500 lg:-left-3 lg:top-3"></div>
                    </div>
                    <div className="absolute -bottom-8 -left-8 lg:-bottom-18 lg:-left-18">
                      <GatsbyImage
                        image={
                          data.orangeCrosses.childImageSharp.gatsbyImageData
                        }
                        className="w-[104px] lg:w-[192px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="mb-20 bg-primary-100 pt-20 pb-20 md:mb-32 md:pt-32 md:pb-36">
            <div className="container">
              <div className="mb-6 grid items-center gap-y-14 md:grid-cols-2 md:gap-x-10 lg:gap-x-28">
                <div className="order-2 md:order-1">
                  <div className="relative mr-8 ml-4 lg:mx-0">
                    <div className="lg::-top-8 lg::-left-8 absolute -top-4 -left-4">
                      <GatsbyImage
                        image={data.blueSquare.childImageSharp.gatsbyImageData}
                        className="w-[86px] lg:w-[160px]"
                      />
                    </div>
                    <div className="relative z-10">
                      {page.personalTaxesQuote ? (
                        <div className="flex items-center rounded-4xl bg-white px-6 py-12 shadow-4xl lg:h-[528px] lg:px-12">
                          <div>
                            <div className="mb-2.5 h-16 w-16 overflow-hidden rounded-full border-2 border-primary-500 lg:mb-4">
                              <GatsbyImage
                                image={
                                  page.personalTaxesQuote.teamMember.headshot
                                    .asset.gatsbyImageData
                                }
                                className="z-0 rounded-full md:rounded-4xl"
                              />
                            </div>
                            <div className="mb-3 text-xs font-medium uppercase tracking-wide text-gray-700/50 lg:mb-5 lg:text-sm">
                              {page.personalTaxesQuote.teamMember.name},{" "}
                              {page.personalTaxesQuote.teamMember.title}
                            </div>
                            <blockquote>
                              <q className="text-sm lg:text-base">
                                {page.personalTaxesQuote.quote}
                              </q>
                            </blockquote>
                          </div>
                        </div>
                      ) : (
                        <GatsbyImage
                          image={data.personal.childImageSharp.gatsbyImageData}
                          className="z-0 w-full rounded-4xl"
                        />
                      )}
                      <div className="lg::left-3 lg::-bottom-3 absolute left-1.5 -bottom-1.5 z-[-10] h-full w-full rounded-3xl bg-primary-500"></div>
                    </div>
                    <div className="lg::-bottom-18 lg::-right-18 absolute -bottom-10 -right-8">
                      <GatsbyImage
                        image={data.blueCrosses.childImageSharp.gatsbyImageData}
                        className="w-[104px] lg:w-[192px]"
                      />
                    </div>
                  </div>
                </div>
                <div className="order-1 md:order-2">
                  <header>
                    <h2>
                      Tax Services for{" "}
                      <span className="text-primary-500">People.</span>
                    </h2>
                    <p>
                      The Problem: taxpayers are not getting the service they
                      deserve from their CPA. Most CPA firms rush through their
                      clients' tax returns, do not properly explain the return,
                      provide no recommendations on how to reduce their taxes
                      and are generally unavailable or unhelpful outside of tax
                      season.
                    </p>
                    <ButtonWithIcon
                      href="/personal-tax-cpa/"
                      text="Tax Solutions for People"
                      altStyle={2}
                    />
                  </header>
                </div>
              </div>
            </div>
          </section>

          <Badges className="mb-20 md:mb-32" />
          <Testimonials category={page.testimonials} />
          <Subscribe />
          <RecentPosts />
          <CallToAction contactOwner={contactOwner} />
          <ModalTeamMembers slideIndex={slideIndex} slides={page.teamMembers} />
        </>
      )}
    </Layout>
  );
};

export default LocationsTemplate;
