import React, { useRef, useEffect } from "react";
import Slick from "react-slick";
import styled from "@emotion/styled";
import tw from "twin.macro";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StyledSlider = styled.div`
  .slick-slide {
    &.slick-active {
      ${tw`z-10`}
    }
  }
  .slick-arrow {
    ${tw`w-16 h-16 rounded-full bg-primary-500 hover:bg-primary-700 flex items-center justify-center text-white hover:text-white text-3xl no-underline`}
  }
  .slick-prev {
    ${tw`md:-left-10 lg:-left-20 xl:-left-24`}
  }
  .slick-next {
    ${tw`md:-right-10 lg:-right-20 xl:-right-24`}
  }
  .slick-dots {
    ${tw`justify-center items-center space-x-2 flex! -bottom-14!`}
    li {
      ${tw`h-2.5 w-2.5 bg-primary-500/40 hover:bg-primary-500 mr-0 rounded-full transition-colors duration-300 ease-linear`}
      &.slick-active {
        ${tw`bg-primary-500`}
      }
    }
  }
`;

const Slider = ({ slideIndex, children }) => {
  const slider = useRef();

  useEffect(() => {
    slider.current.slickGoTo(slideIndex);
  }, [slideIndex]);

  function PrevArrow(props) {
    const { onClick, className } = props;
    return (
      <button
        onClick={onClick}
        className={`slick-prev slick-arrow ${
          className?.includes("slick-disabled") && "!hidden"
        }`}
        aria-label="Previous Slide"
      >
        <i className="fal fa-arrow-left"></i>
      </button>
    );
  }

  function NextArrow(props) {
    const { onClick, className } = props;
    return (
      <button
        onClick={onClick}
        className={`slick-next slick-arrow ${
          className?.includes("slick-disabled") && "!hidden"
        }`}
        aria-label="Next Slide"
      >
        <i className="fal fa-arrow-right"></i>
      </button>
    );
  }

  const sliderSettings = {
    dots: false,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 7500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <StyledSlider>
      <Slick {...sliderSettings} ref={slider}>
        {children}
      </Slick>
    </StyledSlider>
  );
};

export default Slider;
